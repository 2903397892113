import { Dialog } from "@headlessui/react";
import { Button, Heading, TextInput, SelectInput } from "@scandotcom/react";
import { cancelReferral } from "@services/scan";
import { Modal, ModalProps } from "components/common/Modal";
import ValidationErrors from "components/common/ValidationErrors";
import React, { SyntheticEvent, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Routes from "utils/Routes";

interface CancelModalProps extends ModalProps {
  reference?: string | null;
  id?: string | null;
}

const reasons = [
  "",
  "Site too far",
  "Duplicate referral",
  "Test referral",
  "Uncontactable",
  "Case settled/closed",
  "Scanned elsewhere - NHS",
  "Scanned elsewhere - competitor",
  "Too expensive",
  "Clinical safety reason",
  "Did not attend",
].map((reason) => ({ label: reason, value: reason }));

export const CancelReferralModal = ({
  isOpen,
  onClose,
  reference,
  id,
}: CancelModalProps) => {
  const navigate = useNavigate();
  const idInput = useRef(null);
  const queryClient = useQueryClient();
  const [cancellationReason, setCancellationReason] = useState("");
  const [inputtedReference, setInputtedReference] = useState("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const mutation = useMutation(cancelReferral, {
    onSuccess: (data) => {
      if (!data.success || !data.referral) {
        setValidationErrors(data.errors.map(({ fullMessage }) => fullMessage));
        return;
      }
      onClose();
      queryClient.setQueryData(["referral", data.referral.id], data.referral);
      navigate(Routes.referral(data.referral.id), {
        state: { successMessage: "The referral has been cancelled." },
      });
    },
  });

  const matchingReferrals =
    reference?.toLowerCase() === inputtedReference.toLowerCase();

  const isSubmitDisabled =
    mutation.isLoading || inputtedReference === "" || cancellationReason === "";

  const handleFormSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!matchingReferrals || !id) {
      setValidationErrors(["The referral ID is incorrect."]);
      return;
    }

    mutation.mutate({ referralId: id, reason: cancellationReason });
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      initialFocus={idInput}
      className="max-w-md p-10"
    >
      <Modal.Close onClick={onClose} className="-mt-6 -mr-6" />
      <Dialog.Title as={Heading} variant="h4" size="lg" className="mb-3 -mt-3">
        Cancel Referral
      </Dialog.Title>
      <p className="mb-4 text-sm font-normal text-cypress-900">
        Cancelling a referral cannot be reversed. If you cancel a submitted
        referral, the patient will be informed via email. Cancelling a draft
        referral will not notify the patient.
      </p>

      <p className="mb-4 text-sm font-normal text-cypress-900">
        Please confirm you wish to cancel this referral by entering the referral
        ID and a cancellation reason.
      </p>

      {validationErrors.length > 0 && (
        <ValidationErrors errors={validationErrors} />
      )}

      <form onSubmit={handleFormSubmit} className="mt-6 space-y-4">
        <TextInput
          label="Confirm referral reference"
          ref={idInput}
          value={inputtedReference}
          onChange={(e) => setInputtedReference(e.target.value)}
        />

        <SelectInput
          label="Reason for cancellation"
          value={cancellationReason}
          options={reasons}
          onChange={(e) => setCancellationReason(e.target.value)}
        />

        <Button type="submit" className="w-full" disabled={isSubmitDisabled}>
          Cancel referral
        </Button>
      </form>
    </Modal>
  );
};
