import axios, { AxiosRequestConfig } from "axios";
import decodeJwtToken from "services/decodeJwtToken";
import notifyError from "services/notifyError";
import { getToken } from "./utils/getToken";

export async function client<T = any>(
  options: AxiosRequestConfig
): Promise<T | null> {
  const baseURL = window.AppData.ScanApiUrl;

  let token: string;

  if (window.AppData.ViewReferral) {
    token = window.AppData.ViewReferralAccessToken;
  } else {
    token = await getToken();
  }

  if (!token) {
    throw new Error("Couldn't retrieve auth token.");
  }

  try {
    const response = await axios.request<T>({
      ...options,
      baseURL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // The request was manualy cancelled by the user;
      return null;
    }

    const errors = error?.response?.data?.errors || [];

    if (errors.some((err) => err.code === "revoked_token")) {
      // This means the user logged out via another tab.
      // So we can reload the page and it will show the sign-in page
      console.log("revoked_token");
      setTimeout(() => window.location.reload(), 0);
      return null;
    }

    if (error?.response?.data?.success === false) {
      return error.response.data;
    }

    if (options.url === "/api/v1/notifications" && !error?.response) {
      // This URL sometimes returns not response
      // Probably something to do with the fact that it is on a setInterval every minute
      return null;
    }

    const status = error?.response?.status;

    if (status === 404) {
      // No need to record the error if it's a 404
      return null;
    }

    notifyError(`Scan API error ${status}`, {
      token: decodeJwtToken(token),
      request: { method: options.method, url: options.url },
      response: error?.response,
    });
    console.log(JSON.stringify({ response: error?.response }, null, 2));
    return null;
  }
}
